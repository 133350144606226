<template>
  <section>
    <content-header :title="$t('surveyIdentifierMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterSurvey')" />
        </div>
        <div class="row">
          <div class="col-md-3">
            <filter-select
              v-model="filter.companyId"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('company')"
              :options="collections.companies"
            />
          </div>
          <div class="col-md-3">
            <filter-select
              v-model="filter.locationConfigurationId"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('location')"
              :options="collections.locations"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right mt-4">
            <base-filled-button
              class="mx-0"
              icon-class="fa fa-search"
              :on-click="() => getSurveys(1)"
              :text="$t('filter')"
            />
          </div>
        </div>
      </div>

      <separator class="my-4" :text="$t('surveyList')" />

      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(item, index) in surveys.data"
          :key="index"
          class="mt-3 col-12"
        >
          <component :is="`SearchResult`" :value="item" :index="index" />
        </div>
      </div>
      <custom-pagination
        v-if="surveys.count > 0"
        id="pgPager"
        class="my-4"
        :data="surveys"
        :page-size="pageSize"
        @pagination-go-page="getSurveys($event)"
        @pagination-rows-per-page="getSurveys(pageIndex, $event)"
      />
    </div>
    <BigLoader v-if="loading" />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import SearchResult from './components/SearchResult';
import { getSurveys as _getSurveys } from '@/services/SurveyService';
export default {
  name: 'Survey',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    contentHeader,
    SubHeader,
    Separator,
    BigLoader,
  },
  data() {
    return {
      collections: {
        locations: [],
        companies: [],
      },
      filter: {
        companyId: null,
        locationConfigurationId: null,
      },
      surveys: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      loading: false,
      isLocationConfiguration: false,
      model: {},
    };
  },
  mounted() {
    this.getCompanies();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
  },
  watch: {
    currentActiveLocation() {
      this.loading = true;
      setTimeout(() => {
        this.surveys = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
      }, 1200);
    },
    'filter.companyId'(value) {
      this.collections.locations = [];
      this.filter.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
    'filter.locationConfigurationId'(value) {
      if (!value) return (this.filter.isLocationConfiguration = false);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.collections.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getSurveys(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      let locationConfigurationId = this.filter.locationConfigurationId;

      const payload = {
        CompanyId: this.filter.companyId,
        LocationConfigurationId: locationConfigurationId,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      if (
        this.isLocationConfiguration !== this.filter.isLocationConfiguration
      ) {
        this.surveys = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
      }

      this.isLocationConfiguration = this.filter.isLocationConfiguration;

      await _getSurveys(payload)
        .then(({ data }) => {
          this.surveys = data;
          this.noResultsFound = !this.surveys.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
